
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class DeleteAccount extends Vue {

    created() {
    }

}
